<template>
  <div class="barcode-label-container">
    <div>
      <div class="barcode-type">{{ item ? item.id : '' }}</div>
      <div>{{ `${$t('project_version')}: ${item.projectVersion}` }}</div>
      <div>{{ convertedDate }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

// Mixins
import BarcodeTypesMixin from '@/components/mixins/BarcodeTypesMixin.vue'
import { DATE_TIME } from '@/utils/constants'
import { IBarcode } from 'smartbarcode-web-core/src/utils/types/index'
import { format } from 'date-fns'

@Options({
  name: 'RecycleHistoryItem',
})
export default class RecycleHistoryItem extends mixins(BarcodeTypesMixin) {
  @Prop({ type: Object }) readonly item?: IBarcode

  get convertedDate() {
    if (this.item?.audit?.recycledDateTime || this.item?.audit?.deactivatedDateTime) {
      const date = new Date(this.item?.audit?.recycledDateTime || this.item?.audit?.deactivatedDateTime || Date())
      return format(date, DATE_TIME.WITH_SECOND)
    } else {
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';
</style>
