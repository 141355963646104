
import BarcodeLabel from '@/components/BarcodeLabel.vue'
import BarcodeKeyValueInfoMixin from '@/components/mixins/BarcodeKeyValueInfoMixin.vue'
import IconDelete from '@/components/svg/IconDelete.vue'
import { ADD_TRACKING, CLEAR_ERROR, FETCH_BARCODE, SAVE_SCANNED_BARCODES } from '@/store/actions'
import errorHandler from '@/utils/errorHandler'
import { addLabelToBarcode } from '@/utils/helpers'
import { IBarcode, IDisplayBarcodes, IProject, TError } from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { MASKED_ANNOTATION } from 'smartbarcode-web-core/src/utils/constants'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
@Options({
  components: {
    BarcodeLabel,
    IconDelete,
  },
  name: 'ScannedBCList',
})
export default class ScannedBCList extends mixins(BarcodeKeyValueInfoMixin) {
  loading = false
  barcodes = [] as IBarcode[]
  barcodeDialogVisible = false
  barcode = {} as IBarcode
  extParams = {} as Record<string, unknown>

  get MASKED_ANNOTATION() {
    return MASKED_ANNOTATION
  }

  get isBulkAddTracking() {
    return this.$route.params.operation === ADD_TRACKING
  }

  async created() {
    try {
      if (!this.$route.params.operation) {
        const parentBarcodeId = this.$store.state?.barcode?.barcode?.id
        if (!parentBarcodeId) {
          this.$router.replace({ name: 'home' })
          return
        }
        this.$router.replace({
          name: 'detail',
          params: {
            project: this.$store.getters.projectParam,
            barcodeId: parentBarcodeId,
          },
        })
      }
      this.loading = this.displayBarcodes.length > 0
      this.getBarcodeInfo()
      this.extParams = this.$route.params.extParams ? JSON.parse(this.$route.params.extParams) : {}

      this.barcodes = [...this.$store.state.barcode.scannedBCPool]
      if (isEmpty(this.barcodes)) this.rescan()
    } catch (error) {
      errorHandler(error as TError)
    } finally {
      this.loading = false
    }
  }

  get displayBarcodes() {
    const barcodes = cloneDeep(this.barcodes) as IDisplayBarcodes[]
    const currentBC = cloneDeep(this.$store.state.barcode.barcode)
    barcodes.unshift(currentBC)
    addLabelToBarcode(barcodes, this.project)
    return barcodes
  }

  // Reload bc in case user refresh browser
  async getBarcodeInfo() {
    const currentBC = this.$store.state.barcode.barcode
    const urlBCId = this.$route.params?.barcodeId

    if (!currentBC && urlBCId) {
      await this.$store.dispatch(FETCH_BARCODE, { id: this.$route.params?.barcodeId })
    }
  }

  get barcodeName() {
    return this.project?.barcodeTypes?.[this.barcodes[0]?.barcodeType]?.name || ''
  }

  get nextSelectedTrackingPoint(): string {
    return this.extParams?.selectedNextTrackpoint as string
  }

  get project(): IProject {
    return this.$store.state.project.details
  }

  get currentTrackPointKey() {
    return this.barcodes[0]?.currentTrackPointKey
  }

  get currentTrackPointName(): string {
    return this.project?.trackPoints[this.currentTrackPointKey]?.name || ''
  }

  get title(): string {
    return this.$route.params.title ?? this.$t('scanned_barcode_list')
  }

  onViewDetailItem(bc: IBarcode) {
    this.barcodeDialogVisible = true
    this.barcode = bc
  }

  onDeleteItem(bcId: string) {
    this.barcodes = this.barcodes.filter((bc) => bc.id !== bcId)
    this.$store.dispatch(SAVE_SCANNED_BARCODES, [...this.barcodes])
  }

  rescan() {
    this.$router.go(-1)
  }

  forward() {
    this.loading = true
    this.$store.dispatch(this.$route.params.operation, this.extParams)
  }

  get isAllowForcePairing() {
    return this.$store.state.project?.details?.allowForcePairing || false
  }

  @Watch('$store.state.barcode.error')
  operationFailed() {
    this.loading = false
    if (!this.$store.state.barcode.error) return

    const err = this.$store.state.barcode.errorMsg
    if (err === '3044' && this.isAllowForcePairing) {
      this.$confirm('', this.$t('confirm_overwrite_parent'), {
        confirmButtonText: this.$t('ok'),
        confirmButtonClass: 'danger',
        cancelButtonText: this.$t('cancel'),
      })
        .then(async () => {
          this.extParams.forcePairing = true
          this.forward()
        })
        .catch(() => true)
        .finally(() => this.$store.commit(CLEAR_ERROR))
      return
    }
    const modelObject: Record<string, string> = {
      barcodeType: this.barcodeName,
      fTP1: this.currentTrackPointName,
      tTP2: this.nextSelectedTrackingPoint,
    }

    errorHandler(this.$store.state.barcode.errorMsg, modelObject)
    this.$store.commit(CLEAR_ERROR)
  }

  @Watch('$store.state.barcode.actionCompleted')
  operationCompleted() {
    this.loading = false
    const parentBarcodeId = this.$store.state.barcode.barcode.id
    this.$router.replace({
      name: 'detail',
      params: {
        project: this.$store.getters.projectParam,
        barcodeId: parentBarcodeId,
      },
    })
  }
}
