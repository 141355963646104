
import RecycleHistoryItem from '@/components/RecycleHistoryItem.vue'
import { FETCH_BARCODE } from '@/store/actions'
import { getRecycleHistory } from '@/utils/api'
import { IBarcode } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Options({
  components: {
    RecycleHistoryItem,
  },
  name: 'RecycleHistory',
})
export default class RecycleHistory extends Vue {
  loading = true
  histories: IBarcode[] = []

  get project() {
    return this.$store.state.project.details
  }

  get barcode() {
    return this.$store.state.barcode.barcode
  }

  get projectParam() {
    return this.$store.getters.projectParam
  }

  mounted() {
    // Update barcode data in store if barcodeId is different from parameter.
    // This happens when browser history is used to move from another barcode detail page.
    if (this.barcode.id === this.$route.params.barcodeId) {
      this.loadHistory()
    } else {
      this.reloadBarcode()
    }
  }

  async loadHistory() {
    try {
      this.histories = await getRecycleHistory(this.barcode.id)
      this.histories.sort((e1, e2) => {
        const getDate = (e: IBarcode) => new Date(e.audit?.recycledDateTime || e.audit?.deactivatedDateTime || Date())
        return getDate(e1) > getDate(e2) ? -1 : 1
      })
      this.loading = false
    } catch (ex) {}
  }

  reloadBarcode() {
    this.$store.dispatch(FETCH_BARCODE, { id: this.$route.params.barcodeId })
  }

  @Watch('barcode')
  onBarcodeUpdate() {
    this.loadHistory()
  }
}
