<template>
  <div class="display-in-center view-children view" v-loading.fullscreen="loading">
    <div class="view-children__parent-barcode">
      <BarcodeLabel :label="barcode.id" :type="barcode.barcodeType" />
    </div>
    <div class="step-label">{{ $t('Children Barcodes') }}</div>

    <template v-if="barcode.childrenCount > 0">
      <div class="barcode-children" v-for="child in children" :key="child.id">
        <router-link :to="{
          name: 'detail',
          params: {
            project: projectParam,
            barcodeId: child.id,
          },
        }">
          <BarcodeLabel :label="child.label || child?.id || ''" :subLabel="child.id" :type="child.barcodeType" />
        </router-link>
      </div>
    </template>
    <div v-else class="display-in-center">
      {{ $t('No child barcode found') }}
    </div>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getChildren } from '@/utils/api'
import BarcodeLabel from '@/components/BarcodeLabel.vue'
import { FETCH_BARCODE } from '@/store/actions'
import { IBarcode, IDisplayBarcodes, IProject } from 'smartbarcode-web-core/src/utils/types/index'
import BarcodeKeyValueInfoMixin from '@/components/mixins/BarcodeKeyValueInfoMixin.vue'
import { addLabelToBarcode } from '@/utils/helpers'

@Options({
  components: {
    BarcodeLabel,
  },
  name: 'ChildrenView',
})
export default class ChildrenView extends mixins(BarcodeKeyValueInfoMixin) {
  loading = true
  children: IDisplayBarcodes[] = []

  get project(): IProject {
    return this.$store.state.project?.details
  }

  get barcode(): IBarcode {
    return this.$store.state.barcode?.barcode
  }

  get projectParam() {
    return this.$store.getters.projectParam
  }

  async mounted() {
    // Update barcode data in store if barcodeId is different from parameter.
    // This happens when browser history is used to move from another barcode detail page.
    if (this.barcode.id === this.$route.params.barcodeId) {
      await this.loadChildren()
    } else {
      await this.reloadBarcode()
    }
    addLabelToBarcode(this.children, this.project)
  }

  getChildTrackingNumberLabel(child: IDisplayBarcodes) {
    return (
      this.project?.barcodeTypes?.[child?.barcodeType]?.activationFields?.trackingNumber?.label ||
      this.$t('Tracking Number')
    )
  }

  getChildTrackingNumber(child: IDisplayBarcodes) {
    return child?.activationData?.trackingNumber || ''
  }

  async loadChildren() {
    this.children = await getChildren(this.barcode.id)
    this.loading = false
  }

  async reloadBarcode() {
    await this.$store.dispatch(FETCH_BARCODE, { id: this.$route.params.barcodeId })
  }

  @Watch('barcode')
  onBarcodeUpdate() {
    this.loadChildren()
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.step-label {
  margin-bottom: 10px;
}

.barcode-children {
  a {
    color: unset;
    text-decoration: none;
  }
}

.view-children {
  &__parent-barcode {
    .barcode-label-container {
      background: none;
      border-width: 0;
    }
  }
}
</style>
