
import { Options, mixins } from 'vue-class-component'
import { IBarcodeDefinitionType, IBarcode } from 'smartbarcode-web-core/src/utils/types/index'
import { FETCH_BARCODE, FETCH_PROJECT_BY_ID } from '@/store/actions'
import { compileMessage } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import BarcodeTypesMixin from '@/components/mixins/BarcodeTypesMixin.vue'

@Options({
  components: {},
  name: 'CustomHTML',
})
export default class CustomHTML extends mixins(BarcodeTypesMixin) {
  loading = true
  mergedHtml = ''

  get projectParam() {
    return this.$store.getters.projectParam
  }

  get barcode(): IBarcode {
    return this.$store.state.barcode?.barcode
  }

  get project() {
    return this.$store.state.project.details
  }

  get hasTracingHistoryBtn(): boolean {
    return this.barcodeTypeInfo?.customContentTemplates?.[0]?.showTraceHistoryLink ?? false
  }

  get isAuth(): boolean {
    return this.$store.getters.isAuth
  }

  async mounted() {
    if (this.isAuth) {
      this.$router.replace({
        name: 'detail',
        params: {
          barcodeId: this.$route.params.barcodeId,
        },
      })
    } else {
      if (!this.barcode) await this.$store.dispatch(FETCH_BARCODE, { id: this.$route.params.barcodeId })
      if (this.barcode?.projectId !== this.project.id) {
        await this.$store.dispatch(FETCH_PROJECT_BY_ID, this.barcode.projectId)
        this.$router.push({
          name: 'custom-html',
          params: {
            project: this.projectParam,
            barcodeId: this.barcode.id,
          },
        })
      }

      compileMessage(
        this.$route.params.barcodeId,
        this.barcodeTypeInfo?.customContentTemplates?.[0]?.templateHtml ?? ''
      )
        .then((res) => (this.mergedHtml = res))
        .catch((err) => errorHandler(err))
        .finally(() => (this.loading = false))
    }
  }

  get barcodeTypeInfo(): IBarcodeDefinitionType {
    return this.getBarcodeTypeInfo(this.barcode)
  }

  getBarcodeTypeInfo(barcode: IBarcode) {
    return this.currentBarcodeTypeInfo(barcode)
  }

  onMoveToTracingHistory() {
    this.$router.push({
      name: 'trace',
      params: {
        project: this.projectParam,
        barcodeId: this.barcode?.id,
      },
    })
  }

  onMoveToLoginPage() {
    this.$router.push({
      name: 'home',
      query: {
        name: 'detail',
        id: this.barcode?.id,
      },
    })
  }
}
