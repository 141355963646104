<template>
  <div class="flex justify-center" v-loading.fullscreen="loading">
    <div id="custom-html" v-html="mergedHtml"></div>
  </div>
  <div class="text-center">
    <el-button v-if="false" class="tracing-history-button border-2 border-black my-12" @click="onMoveToLoginPage">
      {{ $t('login') }}
    </el-button>
    <el-button
      class="tracing-history-button border-2 border-black my-12"
      v-if="hasTracingHistoryBtn"
      @click="onMoveToTracingHistory"
    >
      {{ $t('tracing_history') }}
    </el-button>
  </div>
</template>
<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { IBarcodeDefinitionType, IBarcode } from 'smartbarcode-web-core/src/utils/types/index'
import { FETCH_BARCODE, FETCH_PROJECT_BY_ID } from '@/store/actions'
import { compileMessage } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import BarcodeTypesMixin from '@/components/mixins/BarcodeTypesMixin.vue'

@Options({
  components: {},
  name: 'CustomHTML',
})
export default class CustomHTML extends mixins(BarcodeTypesMixin) {
  loading = true
  mergedHtml = ''

  get projectParam() {
    return this.$store.getters.projectParam
  }

  get barcode(): IBarcode {
    return this.$store.state.barcode?.barcode
  }

  get project() {
    return this.$store.state.project.details
  }

  get hasTracingHistoryBtn(): boolean {
    return this.barcodeTypeInfo?.customContentTemplates?.[0]?.showTraceHistoryLink ?? false
  }

  get isAuth(): boolean {
    return this.$store.getters.isAuth
  }

  async mounted() {
    if (this.isAuth) {
      this.$router.replace({
        name: 'detail',
        params: {
          barcodeId: this.$route.params.barcodeId,
        },
      })
    } else {
      if (!this.barcode) await this.$store.dispatch(FETCH_BARCODE, { id: this.$route.params.barcodeId })
      if (this.barcode?.projectId !== this.project.id) {
        await this.$store.dispatch(FETCH_PROJECT_BY_ID, this.barcode.projectId)
        this.$router.push({
          name: 'custom-html',
          params: {
            project: this.projectParam,
            barcodeId: this.barcode.id,
          },
        })
      }

      compileMessage(
        this.$route.params.barcodeId,
        this.barcodeTypeInfo?.customContentTemplates?.[0]?.templateHtml ?? ''
      )
        .then((res) => (this.mergedHtml = res))
        .catch((err) => errorHandler(err))
        .finally(() => (this.loading = false))
    }
  }

  get barcodeTypeInfo(): IBarcodeDefinitionType {
    return this.getBarcodeTypeInfo(this.barcode)
  }

  getBarcodeTypeInfo(barcode: IBarcode) {
    return this.currentBarcodeTypeInfo(barcode)
  }

  onMoveToTracingHistory() {
    this.$router.push({
      name: 'trace',
      params: {
        project: this.projectParam,
        barcodeId: this.barcode?.id,
      },
    })
  }

  onMoveToLoginPage() {
    this.$router.push({
      name: 'home',
      query: {
        name: 'detail',
        id: this.barcode?.id,
      },
    })
  }
}
</script>
<style lang="scss" scoped>
#custom-html {
  padding-top: 75px;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  max-width: 320px;

  :deep() {
    h1 {
      font-size: 32px;
    }
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 18.72px;
    }
    h4 {
      font-size: 16px;
    }
    h5 {
      font-size: 13.28px;
    }
    h6 {
      font-size: 10.72px;
    }

    p {
      margin: 0px;
    }
    img {
      max-width: 320px;
    }

    pre.ql-syntax {
      margin: 5px;
      padding: 8px;
      background-color: black;
      color: white;
      border-radius: 3px;
    }

    ul {
      margin-left: 20px;
      list-style-type: disc;
      list-style-position: inside;
      li {
        padding: 3px;
      }
    }

    ol {
      margin-left: 20px;
      list-style-type: decimal;
      list-style-position: inside;
      li {
        padding: 3px;
      }
    }

    s {
      text-decoration: line-through;
    }

    .ql-size-huge {
      font-size: 2em;
    }
    .ql-size-large {
      font-size: 1.5em;
    }
    .ql-size-small {
      font-size: 0.75em;
    }

    .ql-font-monospace {
      font-family: 'Monaco', 'Courier New', 'monospace';
    }

    .ql-font-serif {
      font-family: 'Georgia', 'Times New Roman', 'serif';
    }

    .ql-align-left {
      text-align: left;
    }
    .ql-align-center {
      text-align: center;
    }
    .ql-align-right {
      text-align: right;
    }
    .ql-align-justify {
      text-align: justify;
    }

    & strong {
      font-weight: bold;
    }
    & em {
      font-style: italic;
    }

    blockquote {
      background: #f9f9f9;
      border-left: 10px solid #ccc;
      margin: 1.5em 10px;
      padding: 0.5em 10px;
      quotes: '\201C''\201D''\2018''\2019';
    }
    blockquote:before {
      color: #ccc;
      content: open-quote;
      font-size: 4em;
      line-height: 0.1em;
      margin-right: 0.25em;
      vertical-align: -0.4em;
    }
    blockquote p {
      display: inline;
    }
  }
}

:deep() {
  .tracing-history-button {
    border-radius: 0px !important;
  }
}
</style>
